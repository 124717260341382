import React, { useState, useEffect } from 'react';
import { MarchesApi } from 'api/MarchesApi';
import useFetch from 'services/useFetch';
import useForm from 'services/useForm';

const WalkForm = props => {
    const { fields, setFields } = useForm({});

    const [fieldsDist, setFieldsDist] = useState({});/// Fix for bug 

    const [legendes, setLegendes] = useState([]);
    const [types, setType] = useState([]);

    const { data: countrys } = useFetch('/pays');

    const handleAddDist = e => {
        e.preventDefault();
        if (!fieldsDist.distance || !fieldsDist.heureDepart)
            alert("Il manque une distance ou une heure de départ pour pouvoir l'ajouter")
        else {
            if (fields.distances && (fields?.distances?.length > 0))
                setFields({ ...fields, "distances": [...fields?.distances, fieldsDist] });
            else
                setFields({ ...fields, "distances": [fieldsDist] });
            setFieldsDist({});
        }
    };

    const handleRemoveDist = e => {
        e.preventDefault();
        setFields({ ...fields, "distances": [] });
    };

    const loadLegendes = _ => {
        MarchesApi.legendes.getAll()
        .then(res => setLegendes(res))
    }

    const loadTypes = _ => {
        MarchesApi.types.getAll()
        .then(res => setType(res))
    }

    useEffect(_ => setFields(props.data), [setFields,props])
    useEffect(loadLegendes, [])
    useEffect(loadTypes, [])

    const UpdateLegende = (event,legende) => {
        let data = fields?.legende || [];

            if (event.target.checked && !data.map(e => e.id).includes(legende.id)) //action ajout
                data.push(legende);

            if (!event.target.checked && data.map(e => e.id).includes(legende.id)) //action suppression
                data.splice(data.findIndex(e => e.id === legende.id), 1);

        setFields({ ...fields, "legende": data })
    }

    return (<form onSubmit={e => props.submit(e,fields)}>
        <h1 className="m-3">{props.title || "Gestion de marche"}</h1>
        <h3 className="m-3">Informations</h3>
        <div className="row m-2">
            <div className="col-xl-6">
                <label>Titre de la Marche<Required/></label>
                <input
                    className="form-control"
                    type="text"
                    placeholder="titre"
                    onChange={(e) => setFields({ ...fields, "titreMarche": e.target.value })}
                    value={fields?.titreMarche || ""}
                    required
                />
            </div>
            <div className="col-xl-4">
                <label>Type de Marche<Required /></label>
                <select
                    className="custom-select mr-sm-2"
                    onChange={(e) => setFields({ ...fields, "typeMarcheId": e.target.value })}
                    value={fields?.typeMarcheId ?? ""}
                    required
                >
                    <option value="">Sélectionner un Type de Marche</option>
                    {types?.map(e => <option key={e.id} value={e.id}>{e.libelle}</option>)}
                </select>
            </div>
            <div className="col-xl-2">
                <label>Date de la marche<Required /></label>
                <input
                    className="form-control mr-sm-2"
                    type="date"
                    //min={new Date().toISOString().split('T')[0]}
                    //onChange={(e) => setFields({ ...fields, "date": e.target.value || new Date().toISOString().split('T')[0] })}
                    onChange={(e) => setFields({ ...fields, "date": e.target.value || null })}
                    value={fields?.date?.split('T')[0] ?? ""}
                    required
                />
            </div>
        </div>
            
        <div className="row m-2">
            <div className="col-xl-3">
                <label>Lieu de Départ<Required /></label>
                <input
                    className="form-control"
                    type="text"
                    placeholder=""
                    onChange={(e) => setFields({ ...fields, "lieuDepart": e.target.value })}
                    value={fields?.lieuDepart || ""}
                    required
                />
            </div>
            <div className="col-xl-3">
                <label>Carte IGN</label>
                <input
                    className="form-control"
                    type="text"
                    placeholder=""
                    onChange={(e) => setFields({ ...fields, "carteIgn": e.target.value })}
                    value={fields?.carteIgn || ""}
                />
            </div>
            <div className="col-xl-2">
                <label>Début de la marche permanente</label>
                <input
                    className="form-control mr-sm-2"
                    type="date"
                    //min={new Date().toISOString().split('T')[0]}
                    //onChange={(e) => setFields({ ...fields, "debutMarchePermanente": e.target.value || new Date().toISOString().split('T')[0] })}
                    onChange={(e) => setFields({ ...fields, "debutMarchePermanente": e.target.value || null})}
                    value={fields?.debutMarchePermanente?.split('T')[0] ?? ""}
                />
            </div>
            <div className="col-xl-2">
                <label>Fin de la marche permanente</label>
                <input
                    className="form-control mr-sm-2"
                    type="text"
                    placeholder=""
                    onChange={(e) => setFields({ ...fields, "finMarchePermanente": e.target.value })}
                    value={fields?.finMarchePermanente || ""}
                />
            </div>
        </div>
        <div className="row m-2">
            <div className="col">
                <label>Description</label>
                <textarea
                    className="form-control"
                    placeholder="description"
                    onChange={(e) => setFields({ ...fields, "description": e.target.value })}
                    value={fields?.description || ""}
                />
            </div>
        </div>
            
        <h3 className="m-3">Légende</h3>
        <div className="row m-2">
            <div className="col">
                {legendes?.map(i => <div className="form-check form-check-inline m-3" key={i.id}>
                    <input
                        className="form-check-input"
                        type="checkbox"
                        onChange={e => UpdateLegende(e,i)}
                        checked={fields?.legende?.map(e => e.id).includes(i.id)}
                    />
                    <label className="form-check-label"> {i.libelle} </label>
                </div>)}
            </div>
        </div>
                

        <div className="row">
            <div className="col-xl-8">
                <h3 className="m-3">Distances</h3>
                <div className="row m-2 mt-auto">
                    <div className="col-xl-5">
                        <label>Distances</label>
                        <input
                            className="form-control"
                            type="text"
                            placeholder="par exemple: 2-5-15"
                            onChange={e => setFieldsDist({ "distance": e.target.value, "heureDepart": fieldsDist?.heureDepart })}
                            value={fieldsDist?.distance ?? ""}
                        />
                    </div>
                    <div className="col-xl-5">
                        <label>Heure de départ</label>
                        <input
                            className="form-control"
                            type="text"
                            placeholder="par exemple: 09:00-10:30"
                            onChange={e => setFieldsDist({ "distance" : fieldsDist?.distance , "heureDepart" : e.target.value })}
                            value={fieldsDist?.heureDepart ?? ""}
                        />
                    </div>
                    <div className="col-xl-2 mt-auto">
                        <div className="justify-content-end d-flex">
                            <button className="btn btn-primary m-1" onClick={e => handleAddDist(e)}><i className="fas fa-xs fa-plus mt-2 mb-2 ml-1 mr-1" data-toggle="tooltip" title="Supprimer" /></button>
                            <button className="btn btn-danger m-1" onClick={e => handleRemoveDist(e)}><i className="fas fa-xs fa-trash mt-2 mb-2 ml-1 mr-1" data-toggle="tooltip" title="Supprimer" /></button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-xl-4">
                <ul className="list-group">
                    {fields?.distances?.map((e, i) => <li className="list-group-item" key={i}>{e.distance}  - {e.heureDepart}</li>)}
                </ul>
            </div>
        </div>

        <h3 className="m-3">Lieu</h3>
        <div className="row">
            <div className="col-xl-8">
                <div className="row m-2">
                    <div className="col-xl-8">
                        <label>Rue</label>
                        <input
                            className="form-control"
                            type="text"
                            placeholder="Rue"
                            onChange={(e) => setFields({ ...fields, "adress": { ...fields?.adress, "streetName": e.target.value }})}
                            value={fields?.adress?.streetName || ""}
                        />
                    </div>
                    <div className="col-xl-2">
                        <label>N°</label>
                        <input
                            className="form-control"
                            type="text"
                            placeholder="N°"
                            onChange={(e) => setFields({ ...fields, "adress": { ...fields?.adress, "streetNumber": e.target.value }})}
                            value={fields?.adress?.streetNumber || ""}
                        />
                    </div>
                    <div className="col-xl-2">
                        <label>Boîte</label>
                        <input
                            className="form-control"
                            placeholder="Boîte"
                            type="text"
                            onChange={e => setFields({ ...fields, "adress": {...fields?.adress, "streetNumberBte": e.target.value }})}
                            value={fields?.adress?.streetNumberBte || ""}
                        />
                    </div>
                </div>
                <div className="row m-2">
                    <div className="col-xl-2">
                        <label>Code Postal</label>
                        <input
                            className="form-control"
                            type="text"
                            placeholder="Code Postal"
                            maxLength="10"
                            onChange={(e) => setFields({ ...fields, "adress": { ...fields?.adress, "postalCode": e.target.value }})}
                            value={fields?.adress?.postalCode || ""}
                        />
                    </div>
                    <div className="col-xl-10">
                        <label>Localité</label>
                        <input
                            className="form-control"
                            type="text"
                            placeholder="Localite"
                            maxLength="60"
                            onChange={(e) => setFields({ ...fields, "adress": { ...fields?.adress, "city": e.target.value }})}
                            value={fields?.adress?.city || ""}
                        />
                    </div>
                </div>
                <div className="row m-2">
                    <div className="col-lg-4">
                        <label>Pays</label>
                        <select
                            className="custom-select mr-sm-2"
                            onChange={(e) => setFields({ ...fields, "adress": { ...fields?.adress, "countryCode": e.target.value }})}
                            value={fields?.adress?.countryCode || ""}
                        >
                            <option value="">Sélectionner un Pays</option>
                            {countrys?.map(c => <option key={c.id} value={c.id}>{c.libelle}</option>)}
                        </select>
                    </div>
                </div>
                <div className="row m-2">
                    <div className="col">
                        <label>URL Google Maps</label>
                        <input
                            className="form-control"
                            type="text"
                            placeholder="URL Google Maps"
                            onChange={(e) => setFields({ ...fields, "googleGeoLocalisation": e.target.value })}
                            value={fields?.googleGeoLocalisation || ""}
                        />
                    </div>
                </div>
            </div>
        </div>

        <h3 className="m-3">Paramètres Généraux</h3>
        <div className="form-check form-check-inline m-3">
            <input
                id="Checkfetemobile"
                    className="form-check-input"
                    type="checkbox"
                    value=""
                    onChange={(e) => setFields({ ...fields, "feteMobile": e.target.checked ? "1" : "0" })}
                    checked={fields?.feteMobile === "1"}
            />
            <label className="form-check-label" htmlFor="Checkfetemobile">
                Fête Mobile
            </label>
        </div>
        <div className="form-check form-check-inline m-3">
            <input
                id="Checkvelo"
                className="form-check-input"
                type="checkbox"
                value=""
                onChange={(e) => setFields({ ...fields, "velo": e.target.checked })}
                checked={fields?.velo}
            />
            <label className="form-check-label" htmlFor="Checkvelo">
                Vélo
            </label>
        </div>
        
        {props?.submitLoading ?
            <button className="btn btn-primary m-3" type="button" disabled>
                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                <span className="sr-only">Chargement...</span>
            </button>
            :
            <button type="submit" className="btn btn-primary m-3">Confirmer</button>
        }
    </form>);
}


function Required() {
    return (<b className="text-danger"> *</b>);
}

export default WalkForm;