import React from 'react';
import FormatDate from 'lib/FormatDate';
import Button from '../../0components/Button';
import { PDFDownloadLink, Text } from '../PdfWorker';
import AssuranceLayoutPDF from './AssuranceLayoutPDF';
import { FormatDateShortAndYear } from 'lib/FormatDate';

export default ({ demande }) => {
    return (<PDFDownloadLink document={<MonDocument demande={demande} />} fileName={`DemandeAssurance${demande.numeroDemande}.pdf`}>
        {({ loading }) => (loading ? 'Génération du document...' : <Button icon="fa-download">Télécharger</Button>)}
    </PDFDownloadLink>);
}

const mydate = d => d ? FormatDateShortAndYear(d) : "...../...../.....";
const dates = e => e?.map(elem => `${mydate(elem)}, `) ?? "...../...../.....";

const MonDocument = ({ demande }) => {
    return (<AssuranceLayoutPDF demande={demande} titre="Extensions assurances marcheurs" subtitle="Demande de couverture d'assurance: Police n°45.031.557" roi="ROI ASS 143">

        <Text style={{ fontSize: 10, marginBottom: 10 }}>Le club ci-dessus demande l'extension de la couverture assurance de la police no 45.031.557 pour les membres en ordre de cotisation et repris aux listes nominatives pour :</Text>
        <Text style={{ fontSize: 10, margin: 8 }}>1. La marche, fléchage, déflèchage (- de 42km) : 7 jours maximum à partir du {mydate(demande?.debutFlechageMoins42)} au  {mydate(demande?.finFlechageMoins42)}</Text>
        <Text style={{ fontSize: 10, margin: 8 }}>2. La marche, fléchage, déflèchage (+ de 42km) : 14 jours maximum à partir du {mydate(demande?.debutFlechagePlus42)} au {mydate(demande?.finFlechagePlus42)}</Text>
        <Text style={{ fontSize: 10, margin: 8 }}>3. La reconnaissance du parcours le {dates(demande?.datesReconnaissance)}</Text>
        <Text style={{ fontSize: 10, margin: 8 }}>4. L'A.G. du club le {mydate(demande?.dateAG)}</Text>
        <Text style={{ fontSize: 10, margin: 8 }}>5. Le souper de club le {mydate(demande?.dateSouper)}</Text>
        <Text style={{ fontSize: 10, margin: 8 }}>6. Le prologue organisé le {dates(demande?.datesPrologue)}</Text>
        <Text style={{ fontSize: 10, margin: 8 }}>7. Les marches de détente les {demande?.entrainements?.map(elem => `${mydate(elem.date)}(${elem.localisation}), `) ?? "...../...../....."}</Text>
        <Text style={{ fontSize: 10, margin: 8 }}>8. Le voyage en autocar avec participation à une marche du {mydate(demande?.debutVoyage)} au  {mydate(demande?.finVoyage)} Destination : {demande?.destination ?? ".........................."}</Text>
        {/*<Text style={{ fontSize: 10, margin: 8 }}>8. La visite à un club ami du {mydate(demande?.debutVisite)} au {mydate(demande?.finVisite)} Lieu visité : {demande?.lieuVisite ?? ".........................."}</Text>*/}
        <Text style={{ fontSize: 10, margin: 8 }}>9. Les réunions du club organisées le {dates(demande?.datesReunions)}</Text>
        <Text style={{ fontSize: 10, margin: 8 }}>10. L'exposition, salon ou stand : du {mydate(demande?.debutExpo)} au  {mydate(demande?.finExpo)}</Text>

        <Text style={{ fontSize: 10, marginTop: 20 }}> {">"} Les points 1,2 et 7 concernent les membres repris aux listes nominatives du club. Pour les membres d'une autre club F.F.B.M.P. compléter le formulaire ROI ASS 143 bis.</Text>
        <Text style={{ fontSize: 10, marginTop: 10 }}> {">"} Les points 3, 4, 5, 6, 8 et 9 concernent uniquement les membres du club..</Text>

    </AssuranceLayoutPDF>);
}