import React, { useState } from 'react';
import { Modal, ModalHeader, ModalBody, Button } from 'reactstrap';

import { FormatDateInput, FormatDateShortAndYear } from 'lib/FormatDate';
import CalendrierPdfClub from '../../pdfs/Calendrier/CalendrierPdfClub';
import { useParams } from 'react-router-dom';
import ProjetCalendrierPdfClub from '../../pdfs/Calendrier/ProjetCalendrierPdfClub';
import { Actions, Objects, Show } from '../../../services/AccessControl';
import { MarchesApi } from '../../../api/MarchesApi';

export default ({ marches, reload, isLoading, handleInfoWalk, handleClickCreateMarche }) => {
    const { id } = useParams();

    const [isModalVisible, setIsModalVisible] = useState(false);
    const [marcheModalData, setMarcheModalData] = useState({});

    const handleRedoWalk = (e, w) => {
        e.stopPropagation();
        setIsModalVisible(true);
        setMarcheModalData(w);
    };

    const redoWalk = _ => {
        if (marcheModalData?.id && marcheModalData?.titreMarche && marcheModalData?.date && (new Date(marcheModalData?.date).getTime() >= new Date().getTime())) {
            MarchesApi
                .duplicate3(marcheModalData?.id, marcheModalData?.titreMarche, marcheModalData?.date, marcheModalData?.dateend)
                //.duplicate2(marcheModalData?.id, marcheModalData?.date, marcheModalData?.dateend)
                //.duplicate(marcheModalData?.id, marcheModalData?.date)
                .then(_ => reload())
                .finally(_ => setIsModalVisible(false));
        }
    }

    return (<>
        <div className="row mb-4">
            <div className="col">
                <h3 className="h3">Marches</h3>
            </div>
            <Show object={Objects.Marche} action={Actions.Create}>
                <div className="col-auto">
                    <button type="button" className="btn btn-primary" onClick={handleClickCreateMarche}>Nouvelle Marche <i className="fas fa-fw fa-plus"></i></button>
                </div>
            </Show>
            <div className="col-auto">
                <CalendrierPdfClub id={id} />
            </div>
            <div className="col-auto">
                <ProjetCalendrierPdfClub id={id} />
            </div>
        </div>
        {isLoading && "Chargement..."}
        {!isLoading && <>
            <Modal isOpen={isModalVisible} toggle={_ => setIsModalVisible(false)}>
                <ModalHeader toggle={_ => setIsModalVisible(false)}>Quand renouveler la marche ?</ModalHeader>
                <ModalBody>
                    <form>
                        <label>Titre</label>
                        <input
                            className="form-control mr-sm-2 mb-2"
                            type="text"
                            onChange={e => setMarcheModalData({ ...marcheModalData, "titreMarche": e.target.value })}
                            value={marcheModalData?.titreMarche}
                            required
                        />
                        <label>Date de la nouvelle marche</label>
                        <input
                            className="form-control mr-sm-2 mb-2"
                            type="date"
                            min={FormatDateInput(new Date())}
                            onChange={e => setMarcheModalData({ ...marcheModalData , "date": e.target.value})}
                            value={marcheModalData?.date}
                            required
                        />
                        <label>Date Fin Marche Permanente</label>
                        <input
                            className="form-control mr-sm-2 mb-2"
                            type="text"
                            onChange={e => setMarcheModalData({ ...marcheModalData, "dateend": e.target.value })}
                            value={marcheModalData?.dateend}
                        />
                        <Button color="primary" onClick={_=>redoWalk()}>Confirmer</Button>
                    </form>
                </ModalBody>
            </Modal>
            <div className="card" >
                <div className="table-responsive">
                    <table className="table table-hover mb-0">
                        <thead className="thead-light">
                            <tr>
                                <th scope="col" className="text-dark">Titre</th>
                                <th scope="col" className="text-dark">Date</th>
                                <th scope="col" className="text-dark">Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {marches?.map((w, i) => (<tr key={i} onClick={_ => handleInfoWalk(w?.id)}>
                                <td className="align-middle">{w?.titreMarche}</td>
                                <td className="align-middle">
                                    <span className={`${new Date(w?.date) > new Date() ? (w?.validee ? "text-primary" : "text-warning") : "text-secondary"}`} style={{ display: "block" }}>
                                        <i className="fas fa-fw fa-clock mr-1" />
                                        <span>{w?.date ? FormatDateShortAndYear(w?.date) : "Indisponible"}</span>
                                    </span>
                                </td>
                                <td className="align-middle">
                                    <Show object={Objects.Marche} action={Actions.Create}>
                                        <button type="button" className={`btn ${w.renouvelee ? "btn-info" : "btn-primary"}`} onClick={e => handleRedoWalk(e,w)}>
                                            <i className="fas fa-xs fa-redo m-1" data-toggle="tooltip" title="Réorganiser" />
                                        </button>
                                    </Show>
                                </td>
                            </tr>))}
                        </tbody>
                    </table>
                </div>
            </div>
        </>}
    </>);
};