import React from 'react';

export const Current_version = 'V1.2.36';

export default _ => (<div>
    <div className="jumbotron jumbotron-fluid">
        <div className="container">
            <h1 className="display-4">Mises à jour</h1>
        </div>
    </div>
    <div className="jumbotron jumbotron-fluid">
        <div className="container">
            <h2 className="h2">Version 1.2.36 (17-10-2024)</h2>
            <p className="lead">Amélioration</p>
            <ul>
                <li>Possibilité de modifier le titre de la marche lors du renouvelement.</li>
            </ul>
        </div>
        <div className="container">
            <h2 className="h2">Version 1.2.35 (01-10-2024)</h2>
            <p className="lead">Corrections</p>
            <ul>
                <li>Correction d'affichage du champ 'Date Expo, Salon, Stand' dans l'assurance</li>
            </ul>
        </div>
        <div className="container">
            <h2 className="h2">Version 1.2.34 (23-09-2024)</h2>
            <p className="lead">Corrections</p>
            <ul>
                <li>Correction du champ dupliqué</li>
            </ul>
        </div>
        <div className="container">
            <h2 className="h2">Version 1.2.33 (23-09-2024)</h2>
            <p className="lead">Nouveautés</p>
            <ul>
                <li>Ajout de la section n°10 dans l'assurance A143</li>
                <li>Ajout du role 'Règlement adjoint'</li>
                <li>Ajout d'un champ 'URL Google Maps' dans la marche afin de forcer l'URL affichée sur le site web</li>
            </ul>
        </div>
        <div className="container">
            <h2 className="h2">Version 1.2.32 (16-09-2024)</h2>
            <p className="lead">Corrections</p>
            <ul>
                <li>Correction de la génération du fichier "Carte Province"</li>
            </ul>
        </div>
        <div className="container">
            <h2 className="h2">Version 1.2.31 (23-07-2024)</h2>
            <p className="lead">Corrections</p>
            <ul>
                <li>Correction de la suppression des dates dans l'assurance 143</li>
            </ul>
        </div>
        <div className="container">
            <h2 className="h2">Version 1.2.30 (02-07-2024)</h2>
            <p className="lead">Corrections</p>
            <ul>
                <li>Correction de l'affichage de la date de l'AG dans l'interface</li>
            </ul>
        </div>
        <div className="container">
            <h2 className="h2">Version 1.2.29 (24-06-2024)</h2>
            <p className="lead">Nouveauté</p>
            <ul>
                <li>ASS 143 Bis : Afficher les années des dates YYYY à la place de YY</li>
            </ul>
            <p className="lead">Corrections</p>
            <ul>
                <li>Faute de frappe</li>
                <li>Modification de description dans une marche</li>
            </ul>
        </div>
        <div className="container">
            <h2 className="h2">Version 1.2.28 (05-06-2024)</h2>
            <p className="lead">Nouveauté</p>
            <ul>
                <li>ASS 143 Bis : Amélioration de la date de la Marche de détente</li>
            </ul>
            <p className="lead">Corrections</p>
            <ul>
                <li>ASS 143 Bis : Adaptation du PDF</li>
            </ul>
        </div>
        <div className="container">
            <h2 className="h2">Version 1.2.27 (04-06-2024)</h2>
            <p className="lead">Nouveautés</p>
            <ul>
                <li>ASS 143 : Split de la date d'AG et de la date de Souper</li>
                <li>Renommage du terme 'Entraienement' par 'Marche de détente'</li>
                <li>Ajout du jour dans l'affichage des dates</li>
                <li>Ajout de la gestion des marches de détentes pour l'ASS 143 Bis</li>
            </ul>
        </div>
        <div className="container">
            <h2 className="h2">Version 1.2.26 (29-05-2024)</h2>
            <p className="lead">Nouveauté</p>
            <ul>
                <li>Ajout de la gestion de l'organigramme pour les groupes de type 'PERMA'</li>
            </ul>
        </div>
        <div className="container">
            <h2 className="h2">Version 1.2.25 (17-05-2024)</h2>
            <p className="lead">Corrections</p>
            <ul>
                <li>Adaptation du PDF</li>
                <li>Ajout d'un champ 'Fin de marche permanente' lors de l'encodage</li>
                <li>Ajout des champs 'Début de marche permanente' et 'Fin de marche permanente' dans l'écran de consultation et modification de la marche</li>                
                <li>Correction de l'affichage du responsable provincial sur le document PDF du calendrier</li>
            </ul>
        </div>
        <div className="container">
            <h2 className="h2">Version 1.2.24 (08-04-2024)</h2>
            <p className="lead">Corrections</p>
            <ul>
                <li>Correction de la méthode de fusion des membres</li>
            </ul>
        </div>
        <div className="container">
            <h2 className="h2">Version 1.2.23 (14-01-2024)</h2>
            <p className="lead">Corrections</p>
            <ul>
                <li>Correction du responsable dans les documents de calendrier</li>
            </ul>
        </div>
        <div className="container">
            <h2 className="h2">Version 1.2.22 (29-12-2023)</h2>
            <p className="lead">Nouveauté</p>
            <ul>
                <li>Nouvelle gestion des droits utilisateurs pour l'affichage du menu 'Document'</li>
                <li>Ajout de boutons d'export de calendrier</li>
                <li>Correction des documents de statistiques</li>
                <li>Correction du responsable dans les documents de calendrier</li>
                <li>Améliorations mineurs :</li>
                <li>          Modification de la date d'affiliation par défaut</li>
            </ul>
        </div>
        <div className="container">
            <h2 className="h2">Version 1.2.21 (20-12-2023)</h2>
            <p className="lead">Nouveauté</p>
            <ul>
                <li>Nouvelle gestion des droits utilisateurs</li>
            </ul>
        </div>
        <div className="container">
            <h2 className="h2">Version 1.2.20 (15-12-2023)</h2>
            <p className="lead">Corrections</p>
            <ul>
                <li>Correction de la génération des statistiques de clubs et affiliés</li>
                <li>Création de club, chargement trop long, erreur navigateur</li>
            </ul>
        </div>
        <div className="container">
            <h2 className="h2">Version 1.2.19 (15-12-2023)</h2>
            <p className="lead">Corrections</p>
            <ul>
                <li>Transfert de candidats et dates d'affiliations</li>
                <li>Erreur duplicata de cotisation en Base de donnée</li>
                <li>Recherche sur des membres vides qui retourne des erreurs</li>
                <li>Corrections d'affichage mineures</li>
            </ul>
        </div>
        <div className="container">
            <h2 className="h2">Version 1.2.18 (08-12-2023)</h2>
            <p className="lead">Nouveautés</p>
            <ul>
                <li>Remontée des informations d'erreurs vers plateforme de débug</li>
            </ul>
            <p className="lead">Corrections</p>
            <ul>
                <li>Corrections d'affichage mineures</li>
            </ul>
        </div>
        <div className="container">
            <h2 className="h2">Version 1.2.17 (06-12-2023)</h2>
            <p className="lead">Nouveautés</p>
            <ul>
                <li>Bonne Saint-Nicolas!!</li>
                <li>Affichage des nouveaux membres affiliés à l'année suivante</li>
                <li>Membre | Impression cartes Membres pour année suivante</li>
                <li>CA | Impression cartes Membres pour année suivante (export par province</li>
            </ul>
            <p className="lead">Corrections</p>
            <ul>
                <li>Corrections d'affichage mineures</li>
            </ul>
        </div>
        <div className="container">
            <h2 className="h2">Version 1.2.16 (24-10-2023)</h2>
            <p className="lead">Nouveautés</p>
            <ul>
                <li>Site Web | Modification des prix des bulletins/agenda depuis le portail</li>
                <li>ADMIN | Ajout de permissions spécifiques liées à la gestion du site web</li>
            </ul>   
            <p className="lead">Sécurité</p>
            <ul>
                <li>ajout d'une nouvelle politique de sécurité sur la Création/modification/suppression des marches</li>
                <li>ajout d'une nouvelle politique de sécurité sur la Création/modification/suppression dans les organigrammes</li>
            </ul>
            <p className="lead">Améliorations</p>
            <ul>
                <li>Province | Marches | Ajout de la sélection de l'année en cours +2  dans l'export des marches</li>
            </ul>
            <p className="lead">Corrections</p>
            <ul>
                <li>Corrections d'affichage mineures</li>
            </ul>
        </div>
        <div className="container">
            <h2 className="h2">Version 1.2.15 (12-09-2023)</h2>
            <p className="lead">Améliorations</p>
            <ul>
                <li>CA | Documents | Calendrier | suppression de la virgule en fin d'adresse dans l'export</li>
                <li>CA | Documents | Historique des affiliations d'un club > Forcer le format des dates (pouvait apparaître sous format US pour certains utilisateurs) </li>
                <li>Membres | Suppression d'une affiliation depuis le menu des gestion d'affiliation d'un membre</li>
            </ul>
            <p className="lead">Corrections</p>
            <ul>
                <li>Corrections d'affichage mineures</li>
            </ul>
        </div>
        <div className="container">
            <h2 className="h2">Version 1.2.14.1 (11-08-2023)</h2>
            <p className="lead">Corrections</p>
            <ul>
                <li>Marches | Modification d'une marche impossible pour certains utilisateurs</li>
            </ul>
        </div>
        <div className="container">
            <h2 className="h2">Version 1.2.14 (10-08-2023)</h2>
            <p className="lead">Nouveautés</p>
            <ul>
                <li>Admin | Ajout des groupes concernant les comités dans l'interface de gestion</li>
            </ul>
        </div>
        <div className="container">
            <h2 className="h2">Version 1.2.13 (09-08-2023)</h2>
            <p className="lead">Nouveautés</p>
            <ul>
                <li>Ajout des notifications pour les administrateurs dans l'application</li>
            </ul>
            <p className="lead">Corrections</p>
            <ul>
                <li>Bug Fix et corrections mineures</li>
                <li>Erreur de génération des cartes de membres</li>
            </ul>
        </div>
        <div className="container">
            <h2 className="h2">Version 1.2.12 (04-08-2023)</h2>
            <p className="lead">Nouveautés</p>
            <ul>
                <li>Clubs | Formulaire de création | message d'erreur en cas de problème lors de la création</li>
            </ul>
            <p className="lead">Modifications</p>
            <ul>
                <li>Inversion nom - prénom dans carte membres</li>
                <li>Suppression de la partie Marches permanentes dans le formulaire d'une marche</li>
                <li>Ajout de l'indication du jour de la semaine dans les marches d'un club</li>
                <li>CA | Marches | Modification affichage et ajout des jours de la semaine dans l'affichage</li>
            </ul>
            <p className="lead">Corrections</p>
            <ul>
                <li>Club | Membres | Export Excel | ajout de 0 devant les matricules avec moins de 6 chiffres</li>
                <li>Optimisation | délai de Chargement des demandes d'assurances en attente</li>
                <li>bug: Membre | certains membres ne voyaient plus les options de 'fiche de membre Pdf' et 'carte de membre'</li>
            </ul>
        </div>
        <div className="container">
            <h2 className="h2">Version 1.2.11 (18-07-2023)</h2>
            <p className="lead">Nouveautés</p>
            <ul>
                <li>Clubs | Formulaire de création | Validation des champs </li>
            </ul>
            <p className="lead">Modifications</p>
            <ul>
                <li>Assurances| Nombre maximum de membres dans une A147 était limité à 6</li>
            </ul>
            <p className="lead">Corrections</p>
            <ul>
                <li>Création de club (création plus stable)</li>
                <li>Bug: impossible de Supprimmer des cotisations dans le menu d'affiliation d'un membre</li>
            </ul>
        </div>
        <div className="container">
            <h2 className="h2">Version 1.2.10 (14-06-2023)</h2>
            <p className="lead">Améliorations</p>
            <ul>
                <li>Gestion des Affiliations et payement des cotisation dans l'interface avancée administrateur</li>
            </ul>
            <p className="lead">Corrections</p>
            <ul>
                <li>Correction de certains problèmes de performances</li>
            </ul>
        </div>
        <div className="container">
            <h2 className="h2">Version 1.2.9 (06-06-2023)</h2>
            <p className="lead">Nouveautés</p>
            <ul>
                <li>Fonctionnalité de gestion des clubs actifs et innactifs</li>
            </ul>
            <p className="lead">Améliorations</p>
            <ul>
                <li>Gestion des Affiliations et payement des cotisation dans l'interface avancée administrateur</li>
            </ul>
            <p className="lead">Corrections</p>
            <ul>
                <li>Disparition des adresses lors du transfert par matricule</li>
                <li>Correction de certains problèmes de performances</li>
            </ul>
        </div>

        <div className="container">
            <h2 className="h2">Version 1.2.8 (25-05-2023)</h2>
            <p className="lead">Nouveautés</p>
            <ul>
                <li>Marches | Ordre d'affichage des marches en fonction de leur type de marche</li>
                <li>Documents | Export Calendrier | Ajout du jour de la semaine dans la date </li>
            </ul>
            <p className="lead">Corrections</p>
            <ul>
                <li>Club | membres du club s'affichent 2 fois</li>
                <li>Province | Documents | Correction données manquantes dans pdf Calendrier </li>
                <li>Documents | Calendrier Excel | données manquantes</li>
            </ul>
        </div>
        <div className="container">
            <h2 className="h2">Version 1.2.7 (10-05-2023)</h2>
            <p className="lead">Nouveautés</p>
            <ul>
                <li>Création de demandes d'assurances d'une province</li>
                <li>Modification des informations d'une province</li>
                <li>Documents | Format excel pour l'historique d'affiliations d'un club</li>
                <li>Documents | Export Responsables | Ajout des adresses des responsables</li>
            </ul>
            <p className="lead">Corrections</p>
            <ul>
                <li>Documents | erreur lors de la génération de l'historique d'affiliations d'un club au format CSV</li>
            </ul>
        </div>
        <div className="container">
            <h2 className="h2">Version 1.2.6 (02-05-2023)</h2>
            <p className="lead">Nouveautés</p>
            <ul>
                <li>Création d'une marche pour les provinces</li>
                <li>Ajout d'un export excel des responsables de clubs</li>
                <li>Création de 2 d'un export PDF pour les Organigrammes (type : information)</li>
            </ul>
            <p className="lead">Corrections</p>
            <ul>
                <li>Provinces | Pdfs | Ajout des marches de la province </li>
                <li>Correction erreur de doublons dans les demandes Roi 147</li>
                <li>Correction de l'erreur d'affichage des postes (fusion) dans le document de club destiné à demander une modification d'organigramme pour un club</li>
                <li>Correction erreur 404 lors de l'ouverture du profil d'un membre depuis le menu des candidats (validation)</li>
                <li>Impossible d'ajouter certains membres à une 143Bis</li>
                <li>Bug graphique à la génération de Pdf Organigrammes</li>
            </ul>
        </div>
        <div className="container">
            <h2 className="h2">Version 1.2.5 (20-03-2023)</h2>
            <p className="lead">Nouveautés</p>
            <ul>
                <li>Création de 2 exports PDF pour les Organigrammes CA/CP l'un pour information, l'autre pour notifier d'une modification</li>
            </ul>
            <p className="lead">Modifications</p>
            <ul>
                <li>Sélection des membres par défaut à FAUX dans l'export 'Pdf Code-barres'</li>
                <li>Ajout des informations du club dans l'export 'Pdf Code-barres'</li>
                <li>Ajout du SiteWeb dans le Pdf du calendrier (Projet et info)</li>
                <li>Ajout d'un layer de sécurité suppélmentaire</li>
                <li>Vérification des données plus avancée dans A143Bis</li>
            </ul>
            <p className="lead">Corrections</p>
            <ul>
                <li>Nom groupe manquant dans ROI A147</li>
                <li>Bug lié à la suppression des membres dans A143Bis</li>
            </ul>
        </div>
        <div className="container">
            <h2 className="h2">Version 1.2.4 (10-03-2023)</h2>
            <p className="lead">Nouveautés</p>
            <ul>
                <li>Export des membres actifs vers un PDF reprenant les codes barres repris sur les cartes des membres sélectionnés</li>
            </ul>
            <p className="lead">Modifications</p>
            <ul>
                <li>Nouvelle version compacte pour les Pdfs des organigrammes</li>
            </ul>
            <p className="lead">Corrections</p>
            <ul>
                <li>Erreur de format de dates dans certains exports excels</li>
                <li>Erreur d'adresses dans certaines demandes assurances ROI 201</li>
                <li>Corrections liées à la performance de certaines actions</li>
            </ul>
        </div>
            <div className="container">
                <h2 className="h2">Version 1.2.2 (16-01-2023)</h2>
                <p className="lead">Modifications et Corrections</p>
                <ul>
                    <li>Export Excel des marches (erreur de données dans la case des distances) </li>
                    <li>Export Excel des responsables de clubs</li>
                </ul>
            </div>
            <div className="container">
                <h2 className="h2">Version 1.2.1</h2>
                <p className="lead">Nouveautés</p>
                <ul>
                    <li>Ajout modification du cout des assurances</li>
                    <li>Ajout d'un export au format pdf des codes bar reprénant les affiliés inscrits dans une ROI A134bis pour un voyage en car</li>
                    
                </ul>
                <p className="lead">Modifications et Corrections</p>
                <ul>
                    <li>Modification des exports CSV en Excel</li>
                    <li>Ajout d'un indicateur pour savoir si une marche a été reconduite</li>
                    <li>Modification workflow des affiliations</li>
                </ul>
            </div>
            <div className="container">
                <h2 className="h2">Version 1.2.0</h2>
                <p className="lead">Nouveautés</p>
                <ul>
                    <li>Refonte Graphique</li>
                    <li>Gestion de permissions et multi-club</li>
                    <li>Document d'export de la Fédération Wallonnie-Bx</li>
                </ul>
            </div>
            <div className="container">
                <h2 className="h2">Version 1.1.0 (29-08-2022)</h2>
                <p className="lead">Nouveautés</p>
                <ul>
                    <li>Ajout du support de plusieurs Email par membre</li>
                </ul>
                <p className="lead">Corrections</p>
                <ul>
                    <li>Correction de bugs lors d'exports Excels pour les Admin</li>
                </ul>
            </div>
            <div className="container">
                <h2 className="h2">Version 1.1 </h2>
                <ul>
                    <li>Option de cloture des affiliés après 1 an</li>
                    <li>Mise à jour de la cotisation d'un club lors d'un payement</li>
                    <li>Ajout de 'bte' dans les adresses</li>
                    <li>Export des cartes membres (pdf)</li>
                    <li>Export des calendriers (XLSX)</li>
                    <li>Export des calendriers (pdf)</li>
                    <li>suppression de la limite des marches à valider affichées sur le dashboard</li>
                    <li>Optimisations du code pour augmenter la fluidité de l'application</li>
                    <li>Correction de bugs</li>
                </ul>
            </div>
            <div className="container">
                <h2 className="h2">Version 1.0.10 (25-07-2022)</h2>
                <p className="lead">Corrections</p>
            </div>
            <div className="container">
                <h2 className="h2">Version 1.0.09 (22-07-2022)</h2>
                <p className="lead">Corrections</p>
                <ul>
                    <li>Erreur lors du chargement des donnée d'une demande d'assurance d'un CP</li>
                </ul>
            </div>
            <div className="container">
                <h2 className="h2">Version 1.0.08 (15-07-2022)</h2>
                <p className="lead">Nouveautés</p>
                <ul>
                    <li>Possibilité de modifier la demande d'un membre candidat depuis l'écran de validation</li>
                </ul>
                <p className="lead">Corrections</p>
                <ul>
                    <li>Correction Bug: annuaire: Erreur lors d'une recherche</li>
                    <li>Correction Bug: Login: En cas de permissions trop longues (Admin)</li>
                </ul>
            </div>
            <div className="container">
                <h2 className="h2">Version 1.0.07 (14-07-2022)</h2>
                <p className="lead">Corrections</p>
                <ul>
                    <li>Correction Bug: Fusion de Candidats: Prendre l'adresse du candidat et non celle de l'ancien membre</li>
                </ul>
            </div>
            <div className="container">
                <h2 className="h2">Version 1.0.06 (13-07-2022)</h2>
                <p className="lead">Corrections</p>
                <ul>
                    <li>Correction Bug: Carte Membre: Adaptation du canevas d'impression</li>
                    <li>Correction Bug: Login: Erreurs sur certains navigateurs lors de la connexion</li>
                </ul>
            </div>
            <div className="container">
                <h2 className="h2">Version 1.0.05 (06-07-2022)</h2>
                <p className="lead">Corrections</p>
                <ul>
                    <li>Correction Bug: Annnaire: Un membre fusionné est encore visible pendant 24h</li>
                    <li>Correction Bug: Carte Membre: Adaptation du canevas d'impression</li>
                    <li>Correction Bug: Login: Erreurs sur certains navigateurs lors de la connexion</li>
                </ul>
            </div>
            <div className="container">
                <h2 className="h2">Version 1.0.04 (05-07-2022)</h2>
                <p className="lead">Modifications</p>
                <ul>
                    <li>Ajout Bte devant les boites postales des adresses</li>
                </ul>
                <p className="lead">Corrections</p>
                <ul>
                    <li>Correction Bug: Modification A147</li>
                    <li>Correction Bug: Suppression de membres (fin d'affiliation)</li>
                    <li>Correction Bug: Modification de l'adresse d'une marche</li>
                </ul>
            </div>
            <div className="container">
                <h2 className="h2">Version 1.0.03 (27-06-2022)</h2>
                <p className="lead">Nouveautés</p>
                <ul>
                    <li>Suppression d'une affiliation</li>
                </ul>
                <p className="lead">Corrections</p>
                <ul>
                    <li>Correction bug: Assurance 143Bis: 'date Souper' vide </li>
                </ul>
            </div>
            <div className="container">
                <h2 className="h2">Version 1.0.02 (23-06-2022)</h2>
                <p className="lead">Nouveautés</p>
                <ul>
                    <li>Ajout des modalités de payement dans le document A140</li>
                    <li>Ajout des modalités de payement dans le document A144</li>
                </ul>
                <p className="lead">Corrections</p>
                <ul>
                    <li>Modification d'assurance A144</li>
                    <li>Correction Bug: Modification A140</li>
                    <li>Correction Bug: Modification A143Bis</li>
                    <li>Encodage des adresses en minuscules</li>
                    <li>Correction Bug: Adresses incomplètes dans la liste des affiliés d'un club</li>
                    <li>Optimisations Stabilité</li>
                </ul>
            </div>
            <div className="container">
                <h2 className="h2">Version 1.0.01 (21-06-2022)</h2>
                <p className="lead">Corrections</p>
                <ul>
                    <li>PDF Ass 140: Ajout du cadre avec le montant total et le compte bancaire</li>
                    <li>Inversion de l'ordre d'affichage des assurance dans l'onglet d'assurances</li>
                    <li>Correction Bug: BIC d'un groupe pas modifiable lors de la création d'un groupe</li>
                    <li>Correction Bug: Coordonnées d'un candidat supprimées lors de leur validaiton en affilié</li>
                    
                </ul>
            </div>
            <div className="container">
                <h2 className="h2">Version Beta 0.1.24 (08-06-2022)</h2>
                <p className="lead">Corrections</p>
                <ul>
                    <li>Ajout du nom du club organisateur dans la page regroupant les marches</li>
                    <li>Correction Bug: Correction des errerus de chargement de membres des demandes d'assurances 201 et 143bis</li>
                    <li>Correction Bug: Correction chargement infini lors de la modification des informations d'un utilisteur</li>
                    <li>Correction Bug: Erreurs diverses</li>
                </ul>
            </div>
            <div className="container">
                <h2 className="h2">Version Beta 0.1.23 (03-06-2022)</h2>
                <p className="lead">MAJ Sécurité et corrections de bugs</p>
                <ul>
                    <li>Correction Bug: Adresses vides dans l'export CSV des affiliés d'un club</li>
                    <li>Correction Bug: Erreur lors d'une adresse trop longue</li>
                    <li>Correction Bug: Erreur lors de la génération du pdf des affiliés actifs</li>
                    <li>Correction Bug: Possibilité d'un membre de se transférer dans le même club</li>
                    <li>Suppression d'un membre</li>
                    <li>Sécurisation des accès aux données issues des autres clubs</li>
                    <li>Hash des MDP en DB</li>
                </ul>
            </div>
            <div className="container">
                <h2 className="h2">Version Beta 0.1.22 (25-05-2022)</h2>
                <p className="lead">UI/UX</p>
                <ul>
                    <li>Meilleure gestion des Erreurs</li>
                    <li>Ajout des Libelle des types de demandes d'assurance</li>
                    <li>Mise à jour UI d'une marche</li>
                    <li>Simplification de l'UI du modal d'info d'un club</li>
                    <li>Simplification de l'UI des boutons d'export d'une province</li>
                    <li>Modifications d'UI mineures</li>
                </ul>
            </div>
            <div className="container">
                <h2 className="h2">Version Beta 0.1.21 (19-05-2022)</h2>
                <p className="lead">Export Marches</p>
                <ul>
                    <li>Exportation des Marches au format PDF (Fédéral, Provincial et Club)</li>
                    <li>Augmentation de la limite Maximale de crédit à 2000€</li>
                    <li>Correction de l'erreur liée à l'affiliation par matricule dans les clubs</li>
                    <li>Ordre Membres d'un Club dans page de club</li>
                    <li>Ajout des informations du demandeur d'affiliation dans la liste des demandes à valider et dans la page de candidat</li>
                    <li>Ajout des informations du demandeur d'assurance dans la liste des demandes à valider la page d'une demande d'assurance</li>
                    <li>Ajout des informations du SiteWeb D'un club dans les informations de celui-ci</li>
                    <li>Correction de bugs divers</li>
                </ul>
            </div>
            <div className="container">
                <h2 className="h2">Version Beta 0.1.20 (13-05-2022)</h2>
                <p className="lead">Mises à jour et Modifications</p>
                <ul>
                    <li>Modification du layout du PDF pour l'organigramme d'un club pour le rendre plus compact</li>
                    <li>Modification de l'interface de création de membre pour ne plus rendre les champs nom, prénom, ... obligatoires en cas de transfert via matricule</li>
                    <li>Ajout d'une modification permettant la compatibilité vers EXCEL des CSV</li>
                    <li>Modification du layout du PDF d'une assurance pour y ajouter les annotations en cas de refus ou demande en attente</li>
                    <li>Possibilité pour un CP de valider une demande d'assurance dans sa province (sauf demande 140, 144 et 201)</li>
                    <li>Bloquer la modification pour un non CA des informations nom, prenom et sa date de naissance</li>
                    <li>Mise en place de l'accès limité aux marches pour les CP et Clubs</li>
                </ul>
            </div>
            <div className="container">
                <h2 className="h2">Version Beta 0.1.19 (10-05-2022)</h2>
                <p className="lead">Apperçu des assurances et Corrections</p>
                <ul>
                    <li>Création de la page de gestion des demandes d'assurances</li>
                    <li>Affichage uniquement des assurances auquel l'utilisateur à acces</li>
                    <li>Ajout de l'adresse dans la fiche Pdf d'un membre</li>
                    <li>Suppression du pays dans la carte d'un membre</li>
                    <li>Optimistion des requêtes de récupération des affiliés ( meilleurs performances )</li>
                    <li>Correction du document listing des affiliés (erreur de chargement et lenteurs)</li>
                </ul>
            </div>
            <div className="container">
                <h2 className="h2">Version Beta 0.1.18 (05-05-2022)</h2>
                <p className="lead">Documents - Organigrammes</p>
                <ul>
                    <li>Création document d'organigramme du Club</li>
                    <li>Création document d'organigramme du CP</li>
                    <li>Création document d'organigramme du CA</li>
                    <li>Modification du comportement de la gestion des affiliations</li>
                    <li>Sécurisation du backend</li>
                    <li>Simplification de l'interface affiliation</li>
                    <li>Vérification des données issues des formulaires de nouveaux membres</li>
                    <li>Correction de bugs</li>
                </ul>
            </div>
            <div className="container">
                <h2 className="h2">Version Beta 0.1.17 (19-04-2022)</h2>
                <p className="lead">Corrections</p>
                <ul>
                    <li>Correction des dates de début et de fin lors des transferts entre clubs</li>
                    <li>Suppressin de l'assurance 145</li>
                    <li>Mise en place du formulaire de création d'assurance 201</li>
                    <li>Mise en place du formulaire de modification d'assurance 201</li>
                    <li>Correction des informations contenues dans le PDF 201 (message de total de prix)</li>
                </ul>
            </div>
            <div className="container">
                <h2 className="h2">Version Beta 0.1.16 (13-04-2022)</h2>
                <p className="lead">Corrections</p>
                <ul>
                    <li>Corrections sélection de clubs dans la page des utilisateurs</li>
                    <li>Suppression d'informations sur les visites dans la demande d'assurance A143</li>
                    <li>Ajout des infos supplémentaire dans le PDF des assurances 143bis</li>
                    <li>Correction d'une erreur serveur retournée lors de la recherche d'un matricule vide</li>
                </ul>
            </div>
            <div className="container">
                <h2 className="h2">Version Beta 0.1.15 (12-04-2022)</h2>
                <p className="lead">Gestion des Doublons</p>
                <ul>
                    <li>Corrections du bug en cas de marches vides pour un club</li>
                    <li>Correction des doublons de recherche</li>
                </ul>
            </div>
            <div className="container">
                <h2 className="h2">Version Beta 0.1.14 (08-04-2022)</h2>
                <p className="lead">Modification comportement A140</p>
                <ul>
                    <li>Ajout de la recherche de membre sur base du matricule</li>
                    <li>validation du formulaire de demande d'assurance A140</li>
                </ul>
            </div>
            <div className="container">
                <h2 className="h2">Version Beta 0.1.13 (30-03-2022)</h2>
                <p className="lead">Ajout des clubs provinces </p>
                <ul>
                    <li>Ajout des clubs provinces</li>
                    <li>Corrections Mineures dans le code</li>
                </ul>
            </div>
            <div className="container">
                <h2 className="h2">Version Beta 0.1.12 (28-03-2022)</h2>
                <p className="lead">Corrections et Adaptations</p>
                <ul>
                    <li>*Corr.* dates dans le document d'affiliations</li>
                    <li>Ajout de la suppression de membres (seulement ceux sans documents associés)</li>
                </ul>
            </div>
            <div className="container">
                <h2 className="h2">Version Beta 0.1.11 (15-03-2022)</h2>
                <p className="lead">Modifications demandées par le client</p>
                <ul>
                    <li>Modification de la génération du document CSV reprennant les affiliations en un interval de dates</li>
                </ul>
            </div>
            <div className="container">
                <h2 className="h2">Version Beta 0.1.10 (04-03-2022)</h2>
                <p className="lead">Réaffiliation d'un membre (période décembre)</p>
                <ul>
                    <li>Correction de la période de réaffiliation</li>
                    <li>Modification du comportement de la réaffiliation</li>
                    <li>Correction bug affichage de la fiche d'un affilié (pdf)</li>
                    <li>Correction de bugs</li>
                </ul>
            </div>
            <div className="container">
                <h2 className="h2">Version Beta 0.1.9</h2>
                <p className="lead">Documents généraux</p>
                <ul>
                    <li>Pdf de Statistiques générales</li>
                    <li>Pdf des clubs et de ses membres</li>
                    <li>Pdf des statistiques par Province</li>
                    <li>CSV des affiliations</li>
                    <li>CSV des affiliés par groupe</li>
                    <li>Pdfs Assurance 140</li>
                    <li>Pdfs Assurance 143</li>
                    <li>Pdfs Assurance 143Bis</li>
                    <li>Pdfs Assurance 147</li>
                    <li>Pdfs Assurance 201</li>
                    <li>Gestion des doublons lors de l'inscription d'un nouveau membre</li>
                </ul>
            </div>  
            <div className="container">
                <h2 className="h2">Version Beta 0.1.8</h2>
                <p className="lead">Gestion des Organigrammes</p>
                <ul>
                    <li>Recherche d'un groupe dans les organigrammes</li>
                    <li>Gestion des Erreurs Avancée</li>
                    <li>Optimisation des performances</li>
                    <li>Modification de l'interface d'accueil</li>
                    <li>Correction de bugs</li>
                </ul>
            </div>
            <div className="container">
                <h2 className="h2">Version Beta 0.1.7</h2>
                <p className="lead">Gestion des Organigrammes</p>
                <ul>
                    <li>Affichage des Organigrammes des différents clubs</li>
                    <li>Ajout d'un membre à un poste</li>
                    <li>Modification du poste d'un membre</li>
                    <li>Suppression du poste d'un membre</li>
                    <li>Correction de bugs</li>
                </ul>
            </div>
            <div className="container">
                <h2 className="h2">Version Beta 0.1.6</h2>
                <p className="lead">Gestion des Permissions</p>
                <ul>
                    <li>Implémentation de la nouvelle gestion des utilisateurs</li>
                    <li>Gestion des permissions d'un utilisateur</li>
                    <li>Sécurisation de L'API</li>
                    <li>mise en place de la sécurité du Front End</li>
                    <li>Correction de bugs</li>
                </ul>
            </div>
            <div className="container">
                <h2 className="h2">Version Beta 0.1.5</h2>
                <p className="lead">Gestion des Assurances</p>
                <ul>
                    <li>Affichage d'une demande d'assurance</li>
                    <li>Création d'une demande d'assurance</li>
                    <li>Modification d'une demande d'assurance</li>
                    <li>Validation d'une demande d'assurance</li>
                    <li>Correction de bugs</li>
                </ul>
            </div>
            <div className="container">
                <h2 className="h2">Version Beta 0.1.4</h2>
                <p className="lead">Gestion des Marches et Ergonomie</p>
                <ul>
                    <li>Modification de l'ergonomie de certaines pages</li>
                    <li>Listing des marches</li>
                    <li>Filtre sur la recherche de marches</li>
                    <li>Affichage des marches organisées par un club</li>
                    <li>Fiche récapitulative des informations relatives à une marche</li>
                    <li>Création d'une marche</li>
                    <li>Modification d'une marche</li>
                    <li>Validation d'une marche</li>
                    <li>Correction de bugs</li>
                </ul>
            </div>
            <div className="container">
                <h2 className="h2">Version Beta 0.1.3</h2>
                <p className="lead">Gestion des affiliations et cotisations</p>
                <ul>
                    <li>Gestion des affiliations en attente</li>
                    <li>Gestion des types de groupes</li>
                    <li>Modification de la cotisation d'un membre</li>
                    <li>Gestion des Réaffiliations</li>
                    <li>Payement d'une cotisation pour une réaffiliation</li>
                    <li>Historique des cotisations d'un membre</li>
                    <li>Suppression d'un membre(affilié et en demande d'affiliation)</li>
                    <li>Correction de bugs</li>
                </ul>
            </div>
            <div className="container">
                <h2 className="h2">Version Beta 0.1.2</h2>
                <p className="lead">Modifications et gestion des utilisateurs</p>
                <ul>
                    <li>Modification d'informations d'un club</li>
                    <li>Modification d'informations d'un membre</li>
                    <li>Suppression d'un membre (uniquement pour demande d'affilié)</li>
                    <li>Création d'un utilisateur</li>
                    <li>Modification d'un utilisateur (gestion de droits dans la prochaine release)</li>
                    <li>Suppression d'un utilisateur</li>
                    <li>Sécurisation des accès aux ressources</li>
                    <li>Correction de bugs</li>
                </ul>
            </div>
            <div className="container">
                <h2 className="h2">Version Beta 0.1.1</h2>
                <p className="lead">Mise en place</p>
                <ul>
                    <li>Mise en place de l'application</li>
                    <li>Importation des données du logiciel précedent</li>
                    <li>Sécurisation de l'application via Login</li>
                    <li>Création de la page d'accueil</li>
                    <li>Affichage des différents Clubs</li>
                    <li>Création d'un nouveau Club</li>
                    <li>Affichage des informations d'un club</li>
                    <li>Affichage des membres d'un club</li>
                    <li>Affichage du crédit d'un club</li>
                    <li>Affichage du statut d'un membre</li>
                    <li>Recherche d'un membre parmi ceux du club</li>
                    <li>Affichage d'un membre</li>
                    <li>Création d'un membre</li>
                    <li>Création de la page de paramètres</li>
                    <li>Option de modification du prix d'une cotisation</li>
                </ul>
            </div>
        </div>
    </div>);

